<template>
    <div class="positionT0L0">
        <div class="top">
            <a-form layout="inline">
                <a-form-item>
                    <a-button icon="plus" class="margin_right20" type="primary" @click="addClick('1')">添加对话</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 表格 -->
        <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id" :loading="tableLoading">
            <span slot="direction" slot-scope="direction, record">
                <span>{{ record.direction === 1 ? '番茄妈妈' : '申老师' }}</span>
            </span>
            <span slot="contentType" slot-scope="contentType, record">
                <span v-if="record.contentType === 1">文字</span>
                <span v-else-if="record.contentType === 2">音频</span>
                <span v-else>视频</span>
            </span>
            <span slot="operation" slot-scope="operation, record">
                <a class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
            </span>
        </a-table>

        <a-modal v-model="visibleShow" :title="titleSize" @ok="handleOk" @cancel="handleCancel">
            <a-form-model v-if="visibleShow" ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item ref="direction" label="人物" prop="direction">
                    <a-radio-group v-model="form.direction">
                        <a-radio :value="1">番茄妈妈</a-radio>
                        <a-radio :value="2">申老师</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="contentType" label="类型" prop="contentType">
                    <a-radio-group v-model="form.contentType" @change="contentTypeChange">
                        <a-radio :value="1">文字</a-radio>
                        <a-radio :value="2">音频</a-radio>
                        <a-radio :value="3">视频</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="content" label="内容" prop="content" v-if="form.contentType === 1" key="1">
                    <a-textarea v-model="form.content" placeholder="请输入文字" allow-clear />
                </a-form-model-item>
                <a-form-model-item ref="audio" label="音频" prop="audio" v-if="form.contentType === 2" key="2">
                    <QiniuVideoAudio :oldFileName="form.audio" :type="1" id="videoUpdate" @videoFn="audioFn" @changeOldFileName="audioName" />
                    <div style="font-weight: 600;line-height: 20px">支持mp3 建议文件不超过500M</div>
                    <span v-show="oldFileNameMp3Show" style="color:#f5222d">请上传音频</span>
                </a-form-model-item>
                <a-form-model-item ref="video" label="视频" prop="video" v-if="form.contentType === 3" key="3">
                    <QiniuVideoAudio :oldFileName="form.video" :type="2" id="videoUpdate" @videoFn="videoFn" @changeOldFileName="videoName" />
                    <div style="font-weight: 600;line-height: 20px">支持mp4 建议文件不超过500M</div>
                    <span v-show="oldFileNameMp4Show" style="color:#f5222d">请上传视频</span>
                </a-form-model-item>
                <a-form-model-item ref="sort" label="排序" prop="sort">
                    <a-input-number v-model="form.sort" placeholder="请输入排序" style="width: 100%;"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <router-view />
    </div>
</template>

<script>
import { addQa, qaList, updat } from "@/request/api/appletsManage";
import QiniuVideoAudio from "@/components/file/QiniuVideoAudio.vue";
export default {
    components: { QiniuVideoAudio },
    created () {
        this.topicId = this.$route.params.id
        this.getInitFn()
    },
    data () {
        return {
            topicId: '',
            visibleShow: false,
            tableLoading: false,
            oldFileNameMp3Show: false,
            oldFileNameMp4Show: false,
            titleSize: '',
            columns: [
                {
                    title: "人物",
                    dataIndex: "direction",
                    key: "direction",
                    scopedSlots: { customRender: "direction" },
                },
                {
                    title: "内容",
                    dataIndex: "content",
                    key: "content",
                },
                {
                    title: "内容类型",
                    dataIndex: "contentType",
                    key: "contentType",
                    scopedSlots: { customRender: "contentType" },
                },
                {
                    title: "排序",
                    dataIndex: "sort",
                    key: "sort",
                },
                {
                    title: "操作",
                    width: "100px",
                    dataIndex: "operation",
                    key: "operation",
                    scopedSlots: { customRender: "operation" },
                },
            ],
            tableData: [],
            form: {
                id: '',
                direction: 1,
                contentType: 1,
                sort:'',
                content: '',
                fileUrl: '',
                oldFileVideoName:'',
                audio: '',
                video:'',
            },

            rules: {
                direction: [{ required: true, message: "请选择人物", trigger: "change" }],
                contentType: [{ required: true, message: "选择类型", trigger: "change" }],
                sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
                content: [{ required: true, message: "请输入内容", trigger: "blur" }],
                audio: [{ required: true, message: "" }],
                video: [{ required: true, message: "" }],
            },
        };
    },

    methods: {
        contentTypeChange () {
            this.form.fileUrl = ''
            this.form.content = ''
            this.form.video = ''
            this.form.audio = ''
            this.form.oldFileVideoName = ''
            this.form.oldFileAudioName = ''
        },
        // 视频函数
        videoFn (obj) {
            this.form.fileUrl = obj.fileUrl;
            this.form.video = obj.oldFileName;
        },

        // 改变视频文件名
        videoName (oldFileName) {
            this.form.oldFileVideoName = oldFileName;
        },

        // 音频函数
        audioFn (obj) {
            this.form.fileUrl = obj.fileUrl;
            this.form.audio = obj.oldFileName;
        },

        // 改变音频文件名
        audioName (oldFileName) {
            this.form.oldFileAudioName = oldFileName;
        },

        // 点击新增按钮
        addClick () {
            this.visibleShow = true
            this.titleSize = '新增对话'
        },

        // 编辑新增按钮
        editClick (record) {
            this.form.id = record.id,
            this.form.direction = record.direction
            this.form.content = record.content
            this.form.fileUrl = record.content 
            this.form.contentType = record.contentType
            this.form.sort = record.sort
            if (this.form.contentType == 2) {
                this.form.audio = record.content 
            } else if (this.form.contentType == 3){
                this.form.video = record.content 
            }
            this.titleSize = '编辑对话'
            this.visibleShow = true
        },

        // 原属数据获取
        getInitFn () {
            qaList({
                topicId: this.topicId*1
            }).then(({ code, data }) => {
                this.tableLoading = false
                this.tableData = data;
            });
        },

        // 确定按钮
        handleOk () {
            let self = this
            if (self.form.contentType === 2 && !self.form.audio) {
                self.oldFileNameMp3Show = true
                return
            } else {
                self.oldFileNameMp3Show = false
            }

            if (self.form.contentType === 3 && !self.form.video) {
                self.oldFileNameMp4Show = true
                return
            } else {
                self.oldFileNameMp4Show = false
            }

            self.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (!self.form.id) {
                        addQa({
                            direction: self.form.direction,
                            contentType: self.form.contentType,
                            content: self.form.contentType == 1 ? self.form.content : self.form.contentType == 2 || self.form.contentType == 3 ? self.form.fileUrl : self.form.content,
                            sort: self.form.sort,
                            topicId: self.topicId*1
                        }).then(({ code, data }) => {
                            if (code == 200) {
                                self.getInitFn()
                                self.isNull()
                                self.visibleShow = false
                            } else {
                                self.$message.success(data.msg)
                            }
                        });
                    } else {
                        updat({
                            id: self.form.id,
                            direction: self.form.direction,
                            contentType: self.form.contentType,
                            content: self.form.contentType == 1 ? self.form.content : self.form.contentType == 2 || self.form.contentType == 3 ? self.form.fileUrl : self.form.content,
                            sort: self.form.sort,
                            topicId: self.topicId*1
                        }).then(({ code, data }) => {
                            if (code == 200) {
                                self.getInitFn()
                                self.isNull()
                                self.visibleShow = false
                            } else {
                                self.$message.success(data.msg)
                            }
                        });
                    }
                }
            });
        },

        handleCancel () {
            this.isNull()
        },

        isNull () {
            this.form.id = ''
            this.form.direction = 1
            this.form.content = ''
            this.form.contentType = 1
            this.form.sort = ''
            this.form.video = ''
            this.form.audio = ''
            this.form.fileUrl = ''
            this.form.oldFileVideoName = ''
        },

        // 回到顶部的函数
        targetFn () {
            return document.querySelector("#third_Party_order");
        },
    },
};
</script>

<style lang="less" scoped>
#third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

/deep/.file-name{
    line-height: 15px;
}

.avatar {
    width: 100px;
}</style>

